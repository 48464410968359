.image {
  animation: fadein 0.5s;
}

.locationPageHero {
  box-shadow: '0px 4px 10px rgba(25, 1, 52, 0.16)';
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
