@import '@quno/patient-journey/styles/functions.scss';
@import '@quno/patient-journey/styles/variables.scss';

@mixin variant(
  $background,
  $borderColor,
  $color,
  $hoverBackground,
  $hoverBorderColor,
  $hoverColor,
  $sideBackground: var(--color-white)
) {
  .btn-inner {
    background: $background;
    border-color: $borderColor;
    color: $color;
  }

  &:hover:not(:disabled) {
    .btn-inner {
      background: $hoverBackground;
      border-color: $hoverBorderColor;
      color: $hoverColor;
      transition: {
        duration: 500ms;
        property: background-color, border-color, color;
        timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }

  &-ghost {
    .btn-inner {
      background: transparent !important;
    }
  }

  .btn-side {
    background: $sideBackground;
    border: 1px solid $sideBackground;
    color: $color;
    padding: {
      left: var(--spacing-2);
      right: var(--spacing-2);
      inline: var(--spacing-2);
    }
  }
}

@mixin size(
  $defaultSize,
  $maxHeight,
  $largeSize: false,
  $largeMaxHeight: false
) {
  .btn-side,
  .btn-inner {
    max-height: $maxHeight;
    padding: {
      top: $defaultSize;
      bottom: $defaultSize;
      block: $defaultSize;
    }

    @if $largeSize {
      @media (min-width: $screens-lg) {
        @if $largeMaxHeight {
          max-height: $largeMaxHeight;
        }
        padding: {
          top: $largeSize;
          bottom: $largeSize;
          block: $largeSize;
        }
      }
    }
  }
}

.btn {
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  font: {
    family: NettoWeb, Helvetica, Arial, sans-serif;
    size: toRem(18px);
  }
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-decoration: none !important;

  @media (min-width: $screens-lg) {
    font-size: var(--text-base-size);
  }

  &-inner {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    padding: {
      left: var(--spacing-2);
      right: var(--spacing-2);
      inline: var(--spacing-2);
    }
  }

  &-content {
    margin: {
      left: var(--spacing-2);
      right: var(--spacing-2);
      inline: var(--spacing-2);
    }
  }

  // Variants
  &-orange {
    @include variant(
      #f86b2f,
      #f86b2f,
      #ffffff,
      #dd5b23,
      #dd5b23,
      #ffffff,
      #f86b2f
    );
  }

  &-primary {
    @include variant(
      #ffca58,
      #ffca58,
      #484848,
      #f2b124,
      #f2b124,
      #484848,
      #ffdb8b
    );
  }

  &-secondary {
    @include variant(
      #eef2f6,
      #eef2f6,
      #101010,
      #d8e0e9,
      #d8e0e9,
      #101010,
      #b3bbc4
    );
  }

  &-tertiary {
    @include variant(
      #155263,
      #155263,
      #ffffff,
      #ffffff,
      #155263,
      #101010,
      #1f7b95
    );

    &-ghost {
      .btn-inner {
        color: #155263;
      }

      &:hover:not(:disabled) {
        .btn-inner {
          border-color: var(--color-bluishGreyDarkest);
        }
      }
    }
  }

  // Sizes
  &-tiny {
    // 24px
    @include size(0rem, 24px);
  }

  &-small {
    // 32px
    @include size(0.094rem, 32px);
  }

  &-medium {
    // 48px (mobile) 40px (desktop)
    @include size(0.594rem, 48px, 0.437rem, 40px);
  }

  &-large {
    // 48px
    @include size(0.687rem, 48px);
    font-size: var(--text-lg-size);
  }

  // Misc
  &-link {
    display: inline-flex;
  }

  &-full {
    width: 100%;
    .btn-inner {
      width: 100%;
    }
  }

  &-full-mobile {
    @media (max-width: $screens-md) {
      width: 100%;
      .btn-inner {
        width: 100%;
      }
    }
  }

  &-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  &-icon-before,
  &-side-before {
    flex-direction: row-reverse;
  }

  // Stats
  &:disabled {
    cursor: not-allowed;
  }

  &-disabled {
    &-greyed .btn-inner,
    &-greyed .btn-side {
      background: var(--color-bluishGrey);
      border-color: var(--color-bluishGrey);
      color: var(--color-silverLight);
      opacity: 50%;
    }

    &-faded {
      opacity: 50%;
    }
  }
}
