@import '@quno/patient-journey/styles/variables.scss';

.content {
  background-color: var(--color-white);
  left: 50%;
  max-width: 725px;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  width: 100%;
  z-index: 99999999;

  &.fullScreen {
    max-width: 100vw;
    height: 100%;
  }

  // ALTERNATE
  border-radius: var(--spacing-2-5);

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: toRem(7px);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: var(--spacing-1);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

$size: 23px;

.closeButtonWrapper {
  display: flex;
  justify-content: center;
}

.closeButton {
  right: toRem($size);
  top: toRem($size);

  @media (min-width: $screens-md) {
    right: toRem($size + 17);
    top: toRem($size + 5);
  }

  margin-bottom: var(--spacing-6);
}

.closeIcon {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-width: 0;
  height: var(--spacing-10);
  justify-content: center;
  margin-right: var(--spacing-2);
  margin-top: var(--spacing-4);
  position: absolute;
  right: 0;
  width: var(--spacing-10);
  z-index: 10;
}

.backdrop {
  background-color: var(--color-black);
  height: 100vh;
  left: 0;
  opacity: 0.75;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999995;
}

.backdropBlur {
  background-color: #00000090;
  backdrop-filter: blur(var(--spacing-2));
  opacity: 1;
}

.footer {
  margin-bottom: var(--spacing-6);
}

.prevIcon {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-width: 0;
  color: var(--color-navy);
  justify-content: center;
  left: 0;
  margin-left: var(--spacing-6);
  margin-top: var(--spacing-4);
  position: absolute;
  z-index: 10;
}

.prevIconSvg {
  display: flex;
  align-items: center;
  height: var(--spacing-10);
  justify-content: center;
  width: var(--spacing-10);
}

.progress {
  background-color: var(--color-secondary);
  border-top-right-radius: var(--spacing-1);
  border-bottom-right-radius: var(--spacing-1);
  height: var(--spacing-2);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.progressBar {
  background-color: var(--color-bluishGrey);
  height: var(--spacing-2);
  width: 100%;
}

.title {
  color: var(--color-black);
  font-family: var(--fontFamily-inter);
  font-size: var(--text-2xl-size);
  font-weight: 700;
  letter-spacing: 0;
  padding: 1.3125rem 0;
}

.innerContent {
  padding-inline: 56px;
  padding-block: var(--spacing-10);
}

.z20 {
  z-index: 20;
}

.z30 {
  z-index: 30;
}
