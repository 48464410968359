.spinner {
  animation: circle-rotate 1.4s linear infinite;

  svg > circle {
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 50px;

    &.dash {
      animation: circle-dash 1.4s ease-in-out infinite;
      stroke-dashoffset: 0px;
    }
  }
}
@keyframes circle-rotate {
  0% {
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circle-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}
