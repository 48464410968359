.slideOutModal {
  border-top-left-radius: var(--spacing-3);
  border-top-right-radius: var(--spacing-3);
  overflow: hidden;

  & > div:nth-child(2) {
    overflow-y: scroll;
  }

  & > button {
    background-color: #eee !important;
    width: var(--spacing-10);
    height: var(--spacing-10);
    display: flex;
    align-items: center;
    justify-content: center;
    right: var(--spacing-4-5);
    top: var(--spacing-4-5);
    z-index: 20;
    mix-blend-mode: multiply;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.modalContentMobile {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
  padding-inline: var(--spacing-4);
  padding-top: var(--spacing-10);
  container-type: inline-size;
}

.modalContentDesktop {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: var(--spacing-0-5);
  container-type: inline-size;

  &.fullScreenContent {
    height: 100%;
    max-height: 100vh;
  }

  &.notFullScreenContent {
    height: 70%;
    max-height: 70vh;
  }
}

.fullScreenModal {
  max-width: 100% !important;

  & > div {
    padding: 0;
  }
}

.notFullScreenModal {
  max-width: 1000px !important;
}

.qdModal {
  & > button[data-type='close'] {
    background-color: #eee;
    right: var(--spacing-1-5);
    mix-blend-mode: multiply;
    z-index: 20;
  }
}
