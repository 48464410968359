.spinner {
  display: inline-block;

  svg > circle {
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 50px;
  }
}

.rotate {
  animation: circle-rotate 1.4s linear infinite;
}

.dash {
  svg > circle {
    animation: circle-dash 1.4s ease-in-out infinite;
    stroke-dashoffset: 0;
  }
}

// Variants
.primary {
  svg > circle {
    stroke: var(--spinner-color-primary, var(--color-primary));
  }
}

.secondary {
  svg > circle {
    stroke: var(--spinner-color-secondary, var(--color-secondary));
  }
}

.tertiary {
  svg > circle {
    stroke: var(--spinner-color-tertiary, var(--color-tertiary));
  }
}

@keyframes circle-rotate {
  0% {
    transform-origin: 50% 50%;
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes circle-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}
