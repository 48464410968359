@import '@quno/patient-journey/styles/variables.scss';

.paragraph {
  padding-top: 0 !important;
  margin-bottom: 16px;
  white-space: pre-wrap;

  & ~ h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 32px;
  }
}

.bold {
  font-weight: 700;
}

.unorderedList {
  --list-style-color: var(--color-black);

  padding-left: 0;

  li {
    list-style: none;
    position: relative;
    display: block;
    list-style-type: disc;
    padding-inline-start: 17px;
    unicode-bidi: isolate;

    &::before {
      content: '•';
      left: 0;
      top: 0;
      color: var(--list-style-color);
      position: absolute;
    }
  }
}
