@import '@quno/patient-journey/styles/variables.scss';
@import '@quno/patient-journey/styles/functions.scss';

.SlideOutContent {
  background-color: var(--color-white);
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  width: 100%;
  z-index: 30;

  @media (min-width: $screens-md) {
    width: 40%;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: toRem(7px);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: var(--spacing-1);
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  &.leftPlacement {
    bottom: 0;
    left: 0;
    top: 0;
    transform: translateX(-100%);
  }

  &.rightPlacement {
    bottom: 0;
    right: 0;
    top: 0;
    transform: translateX(100%);
  }

  &.bottomPlacement {
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    width: 100%;
  }

  &.topPlacement {
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-100%);
    width: 100%;
  }

  &.isOpen {
    transition: all 0.3s linear 0.3s;
    transform: translate(0%);
  }
}

.SlideOutContentRounded {
  border-top-left-radius: var(--spacing-3);
  border-top-right-radius: var(--spacing-3);
}

$size: 23px;

.closeBtn {
  position: absolute;
  right: toRem($size);
  top: toRem($size);
  z-index: 10;

  @media (min-width: $screens-md) {
    right: toRem($size + 17);
    top: toRem($size + 5);
  }

  svg {
    width: var(--spacing-5);
    opacity: 0.75;
  }
}

.progressContainer {
  background: var(--color-bluishGrey);
  height: var(--spacing-2);
  width: 100%;
}

.progress {
  background: var(--color-secondary);
  border-top-right-radius: var(--spacing-1);
  border-bottom-right-radius: var(--spacing-1);
  height: var(--spacing-2);
  transition: all 0.3s ease-in-out;
}

.backdropContainer {
  background: var(--color-black);
  left: 0;
  opacity: 0.75;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
}

.prevButton {
  display: flex;
  background: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  z-index: 10;
  margin-left: var(--spacing-2);
  margin-top: var(--spacing-4);
  height: var(--spacing-10);
  width: var(--spacing-10);
}

.headline {
  color: var(--color-black);
  font-family: var(--fontFamily-inter);
  font-size: var(--text-2xl-size);
  font-weight: 700;
  line-height: var(--line-height-normal);
  margin-top: var(--spacing-3);
  margin-inline: var(--spacing-4);
  padding-top: var(--spacing-1);
}

.footer,
.closeButton {
  padding: var(--spacing-8) var(--spacing-6);
}

.z20 {
  z-index: 20;
}

.z50 {
  z-index: 50;
}

.progressContainer {
  height: var(--spacing-2);
  width: 100%;
}

.progress {
  height: var(--spacing-2);
  @apply bg-secondary rounded-r transition-all duration-300 ease-in-out;
}

.backdropContainer {
  background: var(--color-black);
  left: 0;
  opacity: 0.75;
  position: fixed;
  top: 0;
  width: 100%;

  @apply h-screen;
}

.prevButton {
  display: flex;
  background: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  z-index: 10;
  margin-left: var(--spacing-2);
  margin-top: var(--spacing-4);
  height: var(--spacing-10);
  width: var(--spacing-10);
}

.headline {
  color: var(--color-navy);
  font-family: var(--fontFamily-adelle);
  font-size: var(--text-2xl-size);
  font-weight: 700;
  line-height: var(--line-height-normal);
  margin-top: var(--spacing-14);
  padding-top: var(--spacing-1);
  text-align: center;
}

.footer {
  padding: var(--spacing-8) var(--spacing-6);
}

.closeButton {
  padding: var(--spacing-8) var(--spacing-6);
}
