@import 'styles/variables.scss';

@mixin variant(
  $background,
  $borderColor,
  $color,
  $hoverBackground,
  $hoverBorderColor,
  $hoverColor,
  $sideBackground: #ffffff
) {
  & {
    background: $background;
    border-color: $borderColor;
    color: $color;
  }

  /* stylelint-disable no-duplicate-selectors */
  &:hover:not(:disabled) {
    & {
      background: $hoverBackground;
      border-color: $hoverBorderColor;
      color: $hoverColor;
      transition: {
        duration: 500ms;
        property: background-color, border-color, color;
        timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }
  /* stylelint-enable no-duplicate-selectors */


  &-ghost {
    .btn {
      background: transparent !important;
    }
  }

  &-side {
    background: $sideBackground;
    border: 1px solid $sideBackground;
    color: $color;
    padding: {
      left: 0.5rem;
      right: 0.5rem;
      inline: 0.5rem;
    }
  }
}

@mixin size(
  $defaultSize,
  $maxHeight,
  $largeSize: false,
  $largeMaxHeight: false
) {
  .btn-side,
  .btn-inner {
    max-height: $maxHeight;
    padding: {
      top: $defaultSize;
      bottom: $defaultSize;
      block: $defaultSize;
    }

    @if $largeSize {
      @media (min-width: $screens-lg) {
        @if $largeMaxHeight {
          max-height: $largeMaxHeight;
        }

        padding: {
          top: $largeSize;
          bottom: $largeSize;
          block: $largeSize;
        }
      }
    }
  }
}

.btn {
  align-items: center;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  font: {
    family: sans-serif;
    size: var(--text-xl-size);
  }

  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-decoration: none !important;

  @media (min-width: $screens-lg) {
    font-size: var(--text-base-size);
  }

  &-inner {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    padding: {
      left: 0.5rem;
      right: 0.5rem;
      inline: 0.5rem;
    }
  }

  &-content {
    margin: {
      left: 0.5rem;
      right: 0.5rem;
      inline: 0.5rem;
    }
  }

  // Stats
  &:disabled {
    cursor: not-allowed;
  }

  &-disabled {
    &-greyed .btn-inner,
    &-greyed .btn-side {
      background: #eef2f6;
      border-color: #eef2f6;
      color: #cacaca;
      opacity: 50%;
    }

    &-faded {
      opacity: 50%;
    }
  }

  // Misc
  &-link {
    display: inline-flex;
    text-decoration: none;
  }

  &-full {
    width: 100%;

    .btn-inner {
      width: 100%;
    }
  }

  &-full-mobile {
    @media (max-width: $screens-md) {
      width: 100%;

      .btn-inner {
        width: 100%;
      }
    }
  }

  &-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  &-icon-before,
  &-side-before {
    flex-direction: row-reverse;
  }

  // Variants
  &-primary {
    @include variant(
      var(--btn-color-primary, var(--color-primary)),
      var(--btn-borderColor-primary, var(--color-primary)),
      var(--btn-textColor-primary, var(--textColor-primary)),
      var(--btn-color-primary-hover, var(--color-primary-hover)),
      var(--btn-borderColor-primary-hover, var(--color-primary-hover)),
      var(--btn-textColor-primary-hover, var(--textColor-primary)),
      var(--btn-sideColor-primary, var(--color-primary))
    );
  }

  &-secondary {
    @include variant(
      var(--btn-color-secondary, var(--color-secondary)),
      var(--btn-borderColor-secondary, var(--color-secondary)),
      var(--btn-textColor-secondary, var(--textColor-secondary)),
      var(--btn-color-secondary-hover, var(--color-secondary-hover)),
      var(--btn-borderColor-secondary-hover, var(--color-secondary-hover)),
      var(--btn-textColor-secondary-hover, var(--textColor-secondary)),
      var(--btn-sideColor-secondary, var(--color-secondary))
    );
  }

  &-tertiary {
    @include variant(
      var(--btn-color-tertiary, var(--color-tertiary)),
      var(--btn-borderColor-tertiary, var(--color-tertiary)),
      var(--btn-textColor-tertiary, var(--textColor-tertiary)),
      var(--btn-color-tertiary-hover, var(--color-tertiary-hover)),
      var(--btn-borderColor-tertiary-hover, var(--color-tertiary-hover)),
      var(--btn-textColor-tertiary-hover, var(--textColor-tertiary)),
      var(--btn-sideColor-tertiary, var(--color-tertiary))
    );

    &-ghost {
      .btn-inner {
        color: #155263;
      }

      &:hover:not(:disabled) {
        .btn-inner {
          border-color: #d8e0e9;
        }
      }
    }
  }

  // Sizes
  &-tiny {
    // 24px
    @include size(0, 24px);
  }

  &-small {
    // 32px
    @include size(0.094rem, 32px);
  }

  &-medium {
    // 48px (mobile) 40px (desktop)
    @include size(0.594rem, 48px, 0.437rem, 40px);
  }

  &-large {
    // 48px
    @include size(0.687rem, 48px);

    font-size: var(--text-lg-size);
  }
}
